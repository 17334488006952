import forceRefreshPage from './forceRefreshPage';

class AppVersion {
    _isValid = false;
    _version = APP_VERSION;

    get isValid() {
      return this._isValid;
    }

    setValidTimeout() {
      this.validTimeout = setTimeout(() => {
        this._isValid = false;
        console.log('[AppVersion] - reset valid - ready to check again');
      }, 60000);
    }

    clearValidTimeout() {
      clearTimeout(this.validTimeout);
    }

    check() {
      if (this._isValid) {
        console.log('[AppVersion] - check -', 'waiting for valid version to time out');
        return;
      }
      this._isValid = true;

      checkAppVersion(this._version)
        .then(({ message }) => {
          console.log('[AppVersion] - check -', message);
          this.clearValidTimeout();
          this.setValidTimeout();
        })
        .catch(({ reload, message }) => {
          this.clearValidTimeout();
          this.setValidTimeout();
          if (reload) {
            console.log('[AppVersion] - check -', message);
            forceRefreshPage();
          }
        });
    }
}

const appVersion = new AppVersion;

export default appVersion;

function checkAppVersion(version) {
  return fetch(`/version?number=${version}`)
    .then((response) => {
      if (response.ok) {
        return response.json().then(data => {
          return Promise.resolve(data);
        });
      }
      return response.json().then(data => {
        return Promise.reject(data);
      });
    });
}