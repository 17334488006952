exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._Loading-module__container___3RnbC{width:100vw;height:70vh;text-align:center;display:flex;flex-flow:row wrap;max-width:100vw;margin-left:auto;margin-right:auto;position:relative;font-size:23px}._Loading-module__container___3RnbC > *{position:absolute;top:50%;right:auto;bottom:auto;left:50%;transform:translate(-50%, -50%)}\n", ""]);

// exports
exports.locals = {
	"container": "_Loading-module__container___3RnbC"
};