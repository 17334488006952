
export default class JWT {
  static save(token) {
    try {
      window.localStorage.setItem('token', token);
    } catch (e) {
      console.log('[JWT] localStorage Error', e);
    }
  }
  static fetch() {
    let token = null;
    try {
      token = window.localStorage.getItem('token');
    } catch (e) {
      console.log('[JWT] localStorage Error', e);
    }
    return token;
  }
  static destroy() {
    try {
      window.localStorage.removeItem('token');
    } catch (e) {
      console.log('[JWT] localStorage Error', e);
    }
  }
}

window.jwt = JWT;
