
function toPromise(f) {
  return function () {
    return new Promise((resolve, reject) => {
      const result = f.apply(null, Array.from(arguments));
      try {
        return result.then(resolve, reject); // promise.
      } catch (e) {
        if (e instanceof TypeError) {
          resolve(result); // resolve naked value.
        } else {
          reject(e); // pass unhandled exception to caller.
        }
      }
    });
  };
}

/**
 * Simulate Async Operation
 * @param options {Object}
 * @returns {Promise|function(*=)}
 */
export function fakeNapTime(options) {
  const {
    time = 500,
    reject = false,
    creator = false,
    func = null,
  } = options;

  const createPromise = (_func) => {
    return new Promise((res, rej) => {
      setTimeout(() => {
        reject
          ? rej(_func ? _func() : true)
          : res(_func ? _func() : true);
      }, time);
    });
  };

  if (creator) return (_func) => createPromise(_func || func);
  return createPromise(func);
}

/**
 * Ensure a promise takes at least said time
 * @param waitTime {Number}
 * @param promise {Function|Promise}
 * @returns {Promise}
 */
export default function nap(waitTime, promise) {
  const then = Date.now();

  const _promise = (
    promise instanceof Promise
      ? promise
      : toPromise(promise)()
  );

  const logs = [];

  logs.push(`[nap] ${waitTime} ms`);

  function onComplete(complete) {
    return function(result) {
      const timeElapsed = Date.now() - then;

      logs.push(`  - took ${timeElapsed} ms`);

      if (waitTime > timeElapsed) {
        logs.push(`  - wait ${waitTime - timeElapsed} ms`);

        setTimeout(() => {
          console.log(logs.join('\n'));
          complete(result);
        }, waitTime - timeElapsed);
      } else {
        logs.push(`  - immediate`);
        console.log(logs.join('\n'));
        complete(result);
      }
    };
  }

  return new Promise((resolve, reject) => {
    _promise
      .then(onComplete(resolve))
      .catch(onComplete(reject));
  });
}

// const goodPromise = nap.fake({
//     time: 100,
//     creator: true,
//     func(){
//         console.log('GOOD promise done in 100ms...');
//         return 'took a 900ms nap';
//     }
// });
//
// const badPromise = nap.fake({
//     time: 500,
//     creator: true,
//     reject: true,
//     func(){
//         console.log('BAD promise done in 500ms...');
//         return 'it broke, took a 1500ms nap';
//     }
// });
//
//
// nap.ensure(1000, goodPromise)
//     .then((result) => {
//         console.log('GOOD promise:', result);
//     }).catch((e) => {
//     console.log('GOOD promise ERROR:', e);
// });
//
//
// nap.ensure(2000, badPromise)
//     .then((result) => {
//         console.log('BAD promise:', result);
//     }).catch((e) => {
//     console.log('BAD promise ERROR:', e);
// });