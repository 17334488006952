import bugsnag from '@bugsnag/js';

const bugsnagClient = bugsnag({
  apiKey: BUGSNAG_KEY,
  releaseStage: process.env.NODE_ENV,
  notifyReleaseStages: [ 'production', 'staging' ],
  appVersion: APP_VERSION,
});

export default bugsnagClient;
