import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import configureStore from 'state/store';
import JWT from 'utils/JWT';

import App from './App';

const initialState = {
  authenticated: false,
};

if (JWT.fetch()) {
  initialState.authenticated = true;
}

const store = configureStore(initialState);


export default function Root() {
  return (
    <Provider store={store}>
      <Fragment>
        <HashRouter>
          <App/>
        </HashRouter>
      </Fragment>
    </Provider>
  );
}
