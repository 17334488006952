import {
  SET_USER_AUTH,
  SET_USER_UNAUTH,
} from '../types';

const INITIAL_STATE = false;

const INITIAL_ACTION = {
  type: '',
  payload: '',
};

export default function(state = INITIAL_STATE, action = INITIAL_ACTION) {
  switch (action.type) {
    case SET_USER_AUTH:
      return true;

    case SET_USER_UNAUTH:
      return false;
  }

  return state;
}
