
export default function forceRefreshPage() {
  const clickedOK = confirm(`
        Updates have been made to this site. 
        You must refresh to continue.
        Press OK to reload the page.
    `);

  if (!clickedOK) return forceRefreshPage();
  window.location.reload(true);
}